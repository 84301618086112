<p-confirmDialog #cd_info key="info">
  <ng-template pTemplate="headless" let-message>
    <div class="confirmation-dialog">
      <div class="confirmation-dialog__close">
        <p-button (onClick)="closeButtonHandler(cd_info)" severity="secondary"
                  styleClass="p-button-custom p-button-custom--editable p-button--lg">
          <ng-icon name="tablerX" strokeWidth="1.5" />
        </p-button>
      </div>
      <ng-icon class="confirmation-dialog__icon" name="tablerQuestionMark" />
      <h3 class="confirmation-dialog__title">
        {{ message.header }}
      </h3>
      <p class="confirmation-dialog__message">{{ message.message }}</p>
      <div class="confirmation-dialog__buttons">
        <button pButton [label]="rejectButtonLabel(cd_info) | transloco" (click)="cd_info.reject()"
                class="p-button-custom confirmation-dialog__button"></button>
        <button
          pButton
          id="info-focus-trigger"
          [label]="acceptButtonLabel(cd_info) | transloco"
          (click)="cd_info.accept()"
          class="p-button-custom confirmation-dialog__button--confirm"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<p-confirmDialog #cd_warning key="warning">
  <ng-template pTemplate="headless" let-message>
    <div class="confirmation-dialog confirmation-dialog--warning">
      <div class="confirmation-dialog__close">
        <p-button (onClick)="closeButtonHandler(cd_warning)" severity="secondary"
                  styleClass="p-button-custom p-button-custom--editable p-button--lg">
          <ng-icon name="tablerX" strokeWidth="1.5" />
        </p-button>
      </div>
      <ng-icon class="confirmation-dialog__icon" name="tablerExclamationMark" />
      <h3 class="confirmation-dialog__title">
        {{ message.header }}
      </h3>
      <p class="confirmation-dialog__message">{{ message.message }}</p>
      <div class="confirmation-dialog__buttons">
        <button pButton [label]="rejectButtonLabel(cd_info) | transloco" (click)="cd_warning.reject()"
                class="p-button-custom confirmation-dialog__button"></button>
        <button
          pButton
          id="warning-focus-trigger"
          [label]="acceptButtonLabel(cd_info) | transloco"
          (click)="cd_warning.accept()"
          class="p-button-custom confirmation-dialog__button--confirm"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<p-confirmDialog #cd_danger key="danger">
  <ng-template pTemplate="headless" let-message>
    <div class="confirmation-dialog confirmation-dialog--danger">
      <div class="confirmation-dialog__close">
        <p-button (onClick)="closeButtonHandler(cd_danger)" severity="secondary"
                  styleClass="p-button-custom p-button-custom--editable p-button--lg">
          <ng-icon name="tablerX" strokeWidth="1.5" />
        </p-button>
      </div>
      <ng-icon class="confirmation-dialog__icon" name="tablerX" />
      <h3 class="confirmation-dialog__title" data-cy="confirm-dialog-danger-title">
        {{ message.header }}
      </h3>
      <p class="confirmation-dialog__message">{{ message.message }}</p>
      <div class="confirmation-dialog__buttons">
        <button pButton [label]="rejectButtonLabel(cd_info) | transloco" (click)="cd_danger.reject()"
                class="p-button-custom confirmation-dialog__button"></button>
        <button
          pButton
          id="danger-focus-trigger"
          [label]="acceptButtonLabel(cd_info) | transloco"
          (click)="cd_danger.accept()"
          data-cy="confirm-dialog-danger-confirm-button"
          class="p-button-custom confirmation-dialog__button--confirm"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
