import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, effect, inject } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { START_STOP_CONFIG } from '@layouts/dashboard-layout/_configs/start-stop.config';
import { StartStopService } from '@layouts/dashboard-layout/_services/start-stop.service';
import { NgIcon, provideIcons } from '@ng-icons/core';

@Component({
  selector: 'app-start-stop',
  standalone: true,
  imports: [NgIcon, TranslocoPipe],
  templateUrl: './start-stop.component.html',
  styleUrl: './start-stop.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideIcons(START_STOP_CONFIG.icons), StartStopService],
})
export class StartStopComponent implements AfterViewInit {
  protected readonly startStopService = inject(StartStopService);
  protected readonly destroy = inject(DestroyRef);

  constructor() {
    effect(() => {
      if (!this.startStopService.storageService.isUserWorking()) {
        this.removeBeforeUnloadEvent();
      }
    });

    this.destroy.onDestroy(() => this.removeBeforeUnloadEvent());
  }

  ngAfterViewInit(): void {
    window.addEventListener('beforeunload', this.handleBeforeUnload, false);
  }

  removeBeforeUnloadEvent(): void {
    window.removeEventListener('beforeunload', this.handleBeforeUnload, false);
  }

  handleBeforeUnload = (event: any) => {
    if (this.startStopService.storageService.isUserWorking()) {
      return event.preventDefault();
    }
    return true;
  };
}
