<div class="start-stop-wrapper">
  <div
    class="start-stop"
    data-cy="start-stop-trigger"
    [class.is-started]="startStopService.storageService.isUserWorking()"
    (click)="startStopService.toggleWork()"
    [class.is-disabled]="startStopService.processing()">
    <div class="start-stop__icon">
      @if (startStopService.processing()) {
        <ng-icon class="animation-rotate" name="tablerLoader2" size="1.5rem"></ng-icon>
      } @else {
        @if (startStopService.storageService.isUserWorking()) {
          <ng-icon name="tablerPlayerPauseFilled" size="1.25rem"></ng-icon>
        } @else {
          <ng-icon name="tablerPlayerPlayFilled" size="1.25rem"></ng-icon>
        }
      }
    </div>
    <div class="start-stop__title">
      @if (startStopService.storageService.isUserWorking()) {
        {{ '_StartStop.Stop work' | transloco }}
      } @else {
        {{ '_StartStop.Start work' | transloco }}
      }
    </div>
  </div>
  <div class="start-stop-wrapper__info">
    @if (startStopService.storageService.isUserWorking()) {
      {{ '_StartStop.Work in progress' | transloco }}
    } @else {
      {{ '_StartStop.Click to start work' | transloco }}
    }
  </div>
</div>
