import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { CONFIRMATION_DIALOG_CONFIG } from '@layouts/dashboard-layout/_configs/confirmation-dialog.config';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [ConfirmDialogModule, NgIcon, TranslocoPipe, ButtonModule],
  providers: [provideIcons(CONFIRMATION_DIALOG_CONFIG.icons)],
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmationDialogComponent {
  closeButtonHandler(dialog: ConfirmDialog): void {
    if (dialog.confirmation?.closeOnEscape) {
      dialog.reject();
    }
    dialog.hide();
  }

  acceptButtonLabel(dialog: ConfirmDialog): string {
    return dialog.confirmation?.acceptLabel ?? '_Actions.Confirm';
  }

  rejectButtonLabel(dialog: ConfirmDialog): string {
    return dialog.confirmation?.rejectLabel ?? '_Actions.Cancel';
  }
}
