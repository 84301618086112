<div class="notifications-button" #notificationsButton>
  <p-button [styleClass]="buttonClasses()" [rounded]="true" (onClick)="op.toggle($event)" data-cy="notifications-toggle-button">
    <ng-icon name="tablerBellRinging" strokeWidth="1.25" />
  </p-button>
  @if (userNotificationsService.uncompletedNotificationsCount() > 0) {
    <div class="notifications-button__indicator"></div>
  }
</div>
<p-overlayPanel
  #op
  styleClass="p-overlaypanel-custom p-overlaypanel-custom--notifications"
  (onShow)="isButtonActive.set(true)"
  (onHide)="isButtonActive.set(false)">
  <div class="notifications-panel">
    <div class="notifications-panel__header">
      <div class="notifications-panel__header-title">{{ '_Notifications.Notifications' | transloco }}</div>
      <div class="notifications-panel__header-toggle">
        <label for="notifications-only-unread">{{ '_Notifications.Only unread' | transloco }}</label>
        <p-inputSwitch
          styleClass="p-inputswitch-custom"
          (ngModelChange)="onOnlyUnreadChange($event)"
          [(ngModel)]="isOnlyUnread"
          inputId="notifications-only-unread"
          [disabled]="userNotificationsService.loading()" />
      </div>
    </div>
    <div class="notifications-panel__content">
      @if (userNotificationsService.uncompletedNotificationsCount() > 0) {
        <div (click)="userNotificationsService.markAllAsRead(isOnlyUnread)" class="notifications-panel__mark-all-as-read">
          {{ '_Notifications.Mark all as read' | transloco }}
        </div>
      }
      <div class="notifications-panel__infinite-scroll-wrapper">
        <app-user-notification-infinite-scroll [isOnlyUnread]="isOnlyUnread()" />
      </div>
      @if (userNotificationsService.loading()) {
        <div class="notifications-panel__content-loading">
          <ng-icon class="animation-rotate" name="tablerLoader2" size="1.25rem" />
        </div>
      }
    </div>
  </div>
</p-overlayPanel>
