<div id="layout">
  <div id="layout__sidebar">
    <app-sidebar />
  </div>
  <div id="layout__content">
    <app-navbar />
    <router-outlet />
  </div>
</div>
<app-confirmation-dialog />
