import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { EchoService } from '@services/echo.service';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@jsverse/transloco';
import { AgentsService } from '@services/agents.service';
import { DataResponse } from '@interfaces/responses/data-response.interface';
import { Agent } from '@models/agent.model';
import { stopListeningGlobalEcho } from '@helpers/echo-cache.helper';
import { AppRoutes } from '@configs/routes.config';
import { WindowEvent } from '@enums/window-event.enum';

@Injectable({
  providedIn: 'root',
})
export class DashboardLayoutService {
  private readonly destroy = inject(DestroyRef);
  private readonly confirmationService = inject(ConfirmationService);
  private readonly translocoService = inject(TranslocoService);
  readonly agentsService = inject(AgentsService);
  readonly storageService = inject(StorageService);
  readonly authService = inject(AuthService);
  readonly router = inject(Router);
  readonly echo = inject(EchoService);
  readonly errorService = inject(ErrorService);

  constructor() {
    this.updateLoggedInUser();
    window.addEventListener(WindowEvent.LOGOUT_USER, () => this.logoutUser());
  }

  logoutUserConfirmation(): void {
    if (!this.storageService.isUserWorking()) {
      this.logoutUser();
      return;
    }

    this.confirmationService.confirm({
      key: 'info',
      header: this.translocoService.translate('_Dialogs.Logout confirmation'),
      message: this.translocoService.translate('_Dialogs.Are you sure you want to logout?'),
      rejectButtonStyleClass: 'p-button-rounded p-button-custom--cancel',
      acceptButtonStyleClass: 'p-button-rounded',

      accept: () => this.logoutUser(),
    });
  }

  logoutUser(): void {
    this.authService
      .logout()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: () => {
          this.storageService.purge();
          this.echo.disconnect();
          stopListeningGlobalEcho(this.echo);
        },
        error: (response: HttpErrorResponse) => {
          this.errorService.throwError(response);

          if (response.status === HttpStatusCode.Unauthorized) {
            this.storageService.purge();
            this.echo.disconnect();
            stopListeningGlobalEcho(this.echo);
          }
        },
        complete: () => this.router.navigate([AppRoutes.Default, AppRoutes.Auth, AppRoutes.Login]),
      });
  }

  updateLoggedInUser(): void {
    this.agentsService
      .getAgent(this.storageService.getUser()!.id)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: (response: DataResponse<Agent>) => {
          this.storageService.saveUser(new Agent(response.data));
        },
        error: (response: HttpErrorResponse) => this.errorService.throwError(response),
      });
  }
}
