import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { AgentsService } from '@services/agents.service';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';

@Injectable()
export class StartStopService {
  private readonly agentsService = inject(AgentsService);
  private readonly errorService = inject(ErrorService);
  readonly storageService = inject(StorageService);

  processing = signal<boolean>(false);

  toggleWork(): void {
    this.storageService.isUserWorking() ? this.stop() : this.start();
  }

  start(): void {
    this.processing.set(true);
    this.agentsService.startWork().subscribe({
      next: () => {
        this.processing.set(false);
      },
      error: (response: HttpErrorResponse) => {
        this.processing.set(false);
        this.errorService.throwError(response);
      },
    });
  }

  stop(): void {
    this.processing.set(true);
    this.agentsService.stopWork().subscribe({
      next: () => {
        this.processing.set(false);
      },
      error: (response: HttpErrorResponse) => {
        this.storageService.saveUser(this.storageService.getUser()!);
        this.processing.set(false);
        this.errorService.throwError(response);
      },
    });
  }
}
