import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { listenGlobalEcho } from '@helpers/echo-cache.helper';
import {
  ConfirmationDialogComponent,
} from '@layouts/dashboard-layout/_components/confirmation-dialog/confirmation-dialog.component';
import { NavbarComponent } from '@layouts/dashboard-layout/_components/navbar/navbar.component';
import { SidebarComponent } from '@layouts/dashboard-layout/_components/sidebar/sidebar.component';
import { NgIcon } from '@ng-icons/core';
import { EchoService } from '@services/echo.service';
import { StorageService } from '@services/storage.service';
import { ToastService } from '@services/toast.service';
import { PrimeTemplate } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DashboardLayoutService } from './_services/dashboard-layout.service';
import { EchoChannel } from '@enums/echo-channel.enum';
import { EchoEvent } from '@enums/echo-event.enum';
import { EchoEventData } from '@interfaces/echo-event-data.interface';
import { WorkTimeChangedData } from 'types/echo/work-time-changed-data.response';
import { Agent } from '@models/agent.model';

@Component({
  selector: 'app-layouts',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, NavbarComponent, ConfirmDialogModule, PrimeTemplate, NgIcon, ConfirmationDialogComponent],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  private readonly echo = inject(EchoService);
  private readonly storageService = inject(StorageService);
  private readonly dashboardLayoutService = inject(DashboardLayoutService);
  private readonly toastService = inject(ToastService);

  ngOnInit(): void {
    listenGlobalEcho(
      this.echo,
      this.toastService,
      () => this.storageService.getUser(),
      () => this.dashboardLayoutService.logoutUser(),
    );
    this.storageService.listenForUserUpdates(this.echo);

    this.echo.listen(EchoChannel.GLOBAL, EchoEvent.WORK_TIME_CHANGED, (echoEventData: EchoEventData<WorkTimeChangedData>) => {
      const user = this.storageService.user() as Agent;
      if (user?.id === echoEventData.data?.agent_id) {
        user.start = echoEventData.data.start;
        user.stop = echoEventData.data.stop ?? user.stop;
        this.storageService.saveUser(user);
      }
    });
  }

  ngOnDestroy() {
    this.storageService.stopListeningForUserUpdates(this.echo);
    this.echo.stopListening(EchoChannel.GLOBAL, EchoEvent.WORK_TIME_CHANGED);
  }
}
